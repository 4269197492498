<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <b-row class="align-items-center mb-2">
        <b-col
          lg="12"
          md="12"
          sm="12"
        >
          <slot name="breadcrumb">
            <app-breadcrumb />
          </slot>
        </b-col>
        <b-col
          id="header-buttons"
          class="d-flex justify-content-end mt-lg-0 mt-md-1 mt-sm-1 mt-1"
        />
      </b-row>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    AppBreadcrumb,
    BRow,
    BCol,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>

</style>
