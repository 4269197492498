<template>
  <div class="group-list-dropdown">
    <b-nav-item-dropdown
      class="dropdown-notification mr-0 list-style"
      menu-class="dropdown-menu-media"
      left
      @show="dropdownStatus = true"
      @hide="dropdownStatus = false"
    >
      <template #button-content>
        <div>
          <b-avatar
            class="b-avatar"
            :src="getSelectedGroupImage ? getSelectedGroupImage : require('@/assets/images/avatars/Group-place-holder.svg')"
            size="40"
            alt="group img"
          />
          <span
            class="text-nowrap font-weight-bolder b-avatar-name"
          >
            {{ getSelectedGroupName }}
          </span>
          <feather-icon
            v-if="dropdownStatus"
            class="text-body"
            icon="ChevronDownIcon"
            size="21"
          />
          <feather-icon
            v-if="!dropdownStatus"
            class="text-body"
            icon="ChevronRightIcon"
            size="21"
          />
        </div>
      </template>
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <span class="notification-title mb-0 mr-0 font-weight-bolder pl-0">
            Groups List
          </span>
        </div>
      </li>

      <!-- Groups -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- All Groups -->
        <b-link
          v-for="(group, index) in getGroups"
          :key="index"
          :disabled="!group.user_has_group.package.isActive"
          :class="!group.user_has_group.package.isActive ? 'disabled' : null"
          @click="setSelectedGroupId(group.user_has_group.id)"
        >
          <b-media>
            <b-avatar
              style="margin-right: 5px"
              :src="group.user_has_group.image ? group.user_has_group.image : require('@/assets/images/avatars/Group-place-holder.svg')"
              size="sm"
              alt="group img"
            />
            <span
              :class="!group.user_has_group.package.isActive ? 'disabled' : 'media-heading'"
            >
              <span>
                {{ group.user_has_group.name ? group.user_has_group.name :'Group Name' }}
              </span>
              <span
                v-if="!group.user_has_group.package.isActive"
                class="text-nowrap font-small-3 expired-group-tag-class"
              >
                Expired
              </span>
            </span>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BLink, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {

  components: {
    BNavItemDropdown,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      dropdownStatus: false,
    }
  },
  computed: {
    getGroups() {
      return this.$store.getters['user/getAllUserGroups']
    },
    getSelectedGroupName() {
      return this.$store.getters['user/getSelectedGroupName']
    },
    getSelectedGroupImage() {
      return this.$store.getters['user/getSelectedGroupImage']
    },
  },
  methods: {
    setSelectedGroupId(id) {
      this.$store.commit('persistedStore/SET_SELECTED_GROUP_ID', id)
      window.location.href = ('/')
    },
  },
}
</script>

<style lang="scss">
.group-list-dropdown {
  .header-navbar, .navbar-container, .dropdown-menu-media {
    width: 14rem !important;
  }
   .header-navbar, .navbar-container, .dropdown-menu-media, .media-list .media{
     padding: 0.5rem 0.5rem !important;
     border-bottom: none !important;
   }
  .notification-title{
    line-height: 1.2 !important;
    color: #1f58b5;
  }
  a{
    color: #5e5873 !important;
  }
  .dropdown-header{
    padding: 0.5rem 0.28rem !important;
  }
  .group-list-dropdown a,a.disabled{
    cursor: not-allowed;
    opacity: 0.65;
    pointer-events: none;
    color: rgba(94, 88, 115, 0.35) !important;
  }
  .expired-group-tag-class{
    background-color:  rgba(94, 88, 115, 0.15);
    color: rgba(94, 88, 115, 0.78);
    border-radius: 25px;
    padding: 5px 5px;
  }
}

@media (max-width: 580px) {
  .b-avatar{
    width: 20px;
    height: 20px;
  }
  .b-avatar-name{
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
